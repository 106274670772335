/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import FluidImage from 'components/fluid-image';
import Heading from 'components/heading';
import Accordion from 'components/accordion';
import AutoGrid from 'components/auto-grid';
import Link from 'components/link';
import DatesMore from 'components/dates-more';
import Icon from 'components/icon';

const escapeSpecialChar = str => str.split('?').join('\\?');

const ProposalShortcuts = ({ title, items, accordion, initiallyOpenUrls }) => {
  const [openOnPageLoad, setOpenOnPageLoad] = useState();

  useEffect(() => {
    if (!initiallyOpenUrls) return;
    const isMatch = initiallyOpenUrls.some(url => {
      const regExp = new RegExp(`${escapeSpecialChar(url)}/?$`);
      return regExp.test(window.location.href);
    });
    setOpenOnPageLoad(isMatch);
  }, []);

  if (openOnPageLoad === undefined) return null; // Because Accordions "initiallyOpen" prop only works on mount
  return (
    <Accordion
      {...accordion}
      buttonTextForLabel={title}
      initiallyOpen={openOnPageLoad}
    >
      {({ Button, Collapse, isOpen }) => (
        <div
          className={cn('proposal-shortcuts', {
            '-closed': !isOpen
          })}
        >
          {title && (
            <Button className="proposal-shortcuts--button">
              <Heading className="proposal-shortcuts--title">{title}</Heading>
              <Icon
                className={cn('proposal-shortcuts--button-icon', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
                fill={true}
              />
            </Button>
          )}
          <Collapse>
            <AutoGrid className="proposal-shortcuts--grid" minWidth={330}>
              {AutoGridItem =>
                items &&
                items.map(item => (
                  <AutoGridItem
                    className="proposal-shortcuts--grid-item"
                    key={item.articleTitle}
                  >
                    {item.title && (
                      <Heading
                        className="proposal-shortcuts--grid-item-title"
                        level={2} //due to the fact that its nested inside a button, and therefore not considered by accecibility tools
                      >
                        {item.title}
                      </Heading>
                    )}
                    <div className="proposal-shortcuts--item">
                      <a
                        className="proposal-shortcuts--item-link"
                        href={item.link.url}
                      >
                        {item.image && (
                          <div className="proposal-shortcuts--item-link-image">
                            <FluidImage
                              {...item.image}
                              theme={[FluidImage.themes.mosaic, FluidImage.themes.rounded]}
                            />
                          </div>
                        )}
                        <div className="proposal-shortcuts--item-link-text-area">
                          {item.articleTitle && (
                            <Heading
                              className="proposal-shortcuts--item-link-title"
                              level={3}
                            >
                              {item.articleTitle}
                            </Heading>
                          )}
                          {item.text && (
                            <p className="proposal-shortcuts--item-link-text">
                              {item.text}
                            </p>
                          )}
                          {item.status && (
                            <div className="proposal-shortcuts--item-link-status">
                              <div> {item.status}</div>
                            </div>
                          )}
                        </div>
                      </a>
                      {item.moreDates && (
                        <DatesMore
                          className="proposal-shortcuts--item-dates-more"
                          {...item.moreDates}
                        />
                      )}
                    </div>
                  </AutoGridItem>
                ))
              }
            </AutoGrid>
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

ProposalShortcuts.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      articleTitle: PropTypes.string,
      image: PropTypes.exact(FluidImage.propTypes),
      text: PropTypes.string,
      link: PropTypes.exact(Link.propTypes),
      status: PropTypes.string,
      moreDates: PropTypes.exact(DatesMore.propTypes)
    })
  ),
  initiallyOpenUrls: PropTypes.arrayOf(PropTypes.string),
  accordion: PropTypes.exact(Accordion.propTypes)
};

export default ProposalShortcuts;
