import React from 'react';
import PropTypes from 'prop-types';

import FluidImage from 'components/fluid-image';
import Heading from 'components/heading';
import Link from 'components/link';
import DataTagList from 'components/data-tag-list';
import EventImage from 'components/event-image';
import ImageTag from 'components/image-tag';

const Card = ({
  link,
  image,
  preTitle,
  title,
  text,
  imageTag,
  dataTagList,
  eventImage = {},
  isEvent
}) => {
  const LinkOrDiv = link ? 'a' : 'div';
  const linkProps = link
    ? {
      href: link.url,
      'aria-label': link.label
    }
    : undefined;

  return (
    <LinkOrDiv className="card" {...linkProps}>
      {(image || isEvent) && (
        <div className="card--image">
          {imageTag && <ImageTag label={imageTag} />}
          {image && !isEvent && (
            <FluidImage {...image} theme={[FluidImage.themes.mosaic, FluidImage.themes.rounded]} />
          )}
          {isEvent && !image && <EventImage {...eventImage} />}
        </div>
      )}

      <div>
        {preTitle && (
          <div className="card--date">
            <span className="card--date-dot" />
            <div className="card--date-text">{preTitle}</div>
          </div>
        )}
        {title && (
          <Heading className="card--title" level={3}>
            {title}
          </Heading>
        )}
        {text && <p className="card--text">{text}</p>}
        {dataTagList && <DataTagList list={dataTagList} />}
      </div>
    </LinkOrDiv>
  );
};

Card.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(FluidImage.propTypes),
  preTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  imageTag: PropTypes.string,
  dataTagList: PropTypes.arrayOf(PropTypes.string),
  eventImage: PropTypes.exact(EventImage.propTypes),
  isEvent: PropTypes.bool
};

Card.defaultProps = {};

Card.propTypesMeta = {
  isEvent: 'exclude'
};

export default Card;
