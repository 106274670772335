import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Card from 'components/card';
import AutoGrid from 'components/auto-grid';
import DatesMore from 'components/dates-more';

const EventCardList = ({ list }) => {
  const [datesMoreWidth, setDatesMoreWidth] = useState();
  const datesMoreRef = useRef();

  useEffect(() => {
    if (datesMoreRef.current) {
      setDatesMoreWidth(datesMoreRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="event-card-list">
      <AutoGrid minWidth={330}>
        {AutoGridItem =>
          list &&
          list.map(({ eventCard, moreDates, date }) => (
            <AutoGridItem
              className="event-card-list--item"
              key={eventCard.title}
            >
              <Card {...eventCard} isEvent />
              <div className="event-card-list--dates">
                {date && (
                  <div
                    className="event-card-list--date"
                    style={{ paddingRight: `${datesMoreWidth}px` }}
                  >
                    {date}
                  </div>
                )}
                {moreDates && (
                  <DatesMore
                    className="event-card-list--dates-more"
                    {...moreDates}
                    refProp={datesMoreRef}
                  />
                )}
              </div>
            </AutoGridItem>
          ))
        }
      </AutoGrid>
    </div>
  );
};

EventCardList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.exact({
      eventCard: PropTypes.shape(Card.propTypes),
      moreDates: PropTypes.exact(DatesMore.propTypes),
      date: PropTypes.string
    })
  )
};

export default EventCardList;
